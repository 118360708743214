import { Injectable } from '@angular/core';
import { CacheService } from './caches.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserauthService {

  constructor(private httpClient: HttpClient, private route: Router, private Authservice: AuthService) {
  }


  validatetoken() {
    this.httpClient.get<any>(`${environment.APIURL}users/valauth/${this.Authservice.getToken()}`).subscribe(
      data => {
        if (data.code === 0) {
          this.route.navigate(['/']);
        }
      }
    );
  }



}
