import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DashboardComponent } from './dashboard.component';
import { MaterialModule } from '../material/material.module';
import { RatingModule } from 'ng-starrating';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserComponent } from './user/user.component';
import { LoaderComponent } from './alerts/loader/loader.component';
import { NetworkErrorComponent } from './alerts/network-error/network-error.component';
import { TransactionComponent } from './transaction/transaction.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ListComponent } from './transaction/list/list.component';
import { DataTablesModule } from 'angular-datatables';
import { DetailsComponent } from './transaction/list/details/details.component';
import { AccountComponent } from './account/account.component';
import { UserDetailsComponent } from './account/user-details/user-details.component';
import { EditUserComponent } from './account/user-details/edit-user/edit-user.component';
import { FundWalletComponent } from './account/user-details/fund-wallet/fund-wallet.component';
import { GenerateBillComponent } from './account/user-details/generate-bill/generate-bill.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { AddPromotionsComponent } from './promotions/add-promotions/add-promotions.component';
import { StaffManagementComponent } from './admin/staff-management/staff-management.component';
import { WalletManagementComponent } from './admin/wallet-management/wallet-management.component';
import { AddStaffComponent } from './admin/staff-management/add-staff/add-staff.component';
import { EditStaffComponent } from './admin/staff-management/edit-staff/edit-staff.component';
import { DiscosComponent } from './discos/discos.component';
import { ListdiscosComponent } from './discos/listdiscos/listdiscos.component';
import { ChartsModule } from 'ng2-charts';
import { AddFundComponent } from './admin/staff-management/add-fund/add-fund.component';


@NgModule({
  declarations: [
    DashboardComponent,
    SidebarComponent,
    UserComponent,
    LoaderComponent,
    NetworkErrorComponent,
    TransactionComponent,
    ListComponent,
    DetailsComponent,
    AccountComponent,
    UserDetailsComponent,
    EditUserComponent,
    FundWalletComponent,
    GenerateBillComponent,
    PromotionsComponent,
    AddPromotionsComponent,
    StaffManagementComponent,
    WalletManagementComponent,
    AddStaffComponent,
    EditStaffComponent,
    DiscosComponent,
    ListdiscosComponent,
    AddFundComponent
  ],

  imports: [
    DashboardRoutingModule,
    CommonModule,
    MaterialModule,
    CKEditorModule,
    InfiniteScrollModule,
    RatingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    DataTablesModule,
    ChartsModule,
    NgxDaterangepickerMd.forRoot({
      applyLabel: 'Okay',
    })
  ],

  entryComponents: [
    EditUserComponent,
    FundWalletComponent,
    GenerateBillComponent,
    AddStaffComponent,
    EditStaffComponent,
    AddFundComponent
  ]
})
export class DashboardModule { }
