import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth/auth.service";
import { UiService } from "../../common/ui.service";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  loading = false;

  networkerror = false;

  success = false;

  getParam;

  data: any;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.loadData();
  }

  role;

  loadData() {

    this.loading = true;

    this.httpClient.get<any>(`${environment.APIURL}profile`).subscribe(
      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          this.role = data.user ? data.user.role : null;
          return true;
        }

        return false;

      },
      error => {
      }
    );

  }

  onLogOut() {

    this.authService.logout();

    this.router.navigate(['/']);

  }

}
