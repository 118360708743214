import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public env  =  'local';
  public url;
  public version = 'v1';

  public isLoggedIn = false;
  public authToken = '';
  public loading = false;
  public nextUrl = '';
  public publicKey = '';

  constructor(public http: HttpClient,public router: Router,
              public authService : AuthService) {
    this.setup();
  }

  setup() {
    this.url = environment.baseUrl + 'admin/';
    this.authToken = this.authService.getToken();

  }

  isUserLoggedIn(){
    this.isLoggedIn = localStorage.getItem("token") !== null;
    let user = localStorage.getItem("baker");
    let token = localStorage.getItem("token");

    if( user !==  null){
      user = JSON.parse(user);
      this.authToken = token;
    }
  }

  signUp(user) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    let body = JSON.stringify(user);
    return this.http.post(this.url + "sign-up",body,{headers});
  }

  signIn(user) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    let body = JSON.stringify(user);
    return this.http.post(this.url + "sign-in",body,{headers});
  }

  // authorized routes

  toggleApiReseller(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "toggle-api-reseller",body,{headers});
  }

  retryTransactions(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "retry-transactions",body,{headers});
  }


}
