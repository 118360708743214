import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './material/material.module';
import { FourofourComponent } from './home/fourofour/fourofour.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { DataService } from './common/data.service';
import { UiService } from './common/ui.service';
import { AuthHttpInterceptor } from './auth/auth-http-interceptor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Angular4PaystackModule } from 'angular4-paystack';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DashboardModule } from './dashboard/dashboard.module';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FourofourComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    CarouselModule,
    Angular4PaystackModule,
    CKEditorModule,
    DashboardModule,
    LazyLoadImageModule,
    DataTablesModule
  ],
  providers: [
    AuthService,
    DataService,
    UiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
