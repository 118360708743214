import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth/auth.service";
import { UiService } from "../../common/ui.service";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  loading = false;

  success = false;

  networkerror = false;

  data: any;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService
  ) { }

  ngOnInit() {

    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.loadData();

  }

  loadData() {

    this.httpClient.get<any>(`${environment.APIURL}promotions`).subscribe(

      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          return true;
        }

        this.networkerror = true;

        return false;

      },

      error => {

        this.loading = false;

        this.networkerror = true;
      }

    );

  }

  reloadNetwork() {
    this.ngOnInit();
  }

}
