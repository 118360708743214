import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { UiService } from '../../common/ui.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-discos',
  templateUrl: './discos.component.html',
  styleUrls: ['./discos.component.scss']
})
export class DiscosComponent implements OnInit {

  profitToday = 0;

  profitWeek = 0;

  profitMonth = 0;

  profitYear = 0;

  loading = false;

  success = false;

  networkerror = false;

  data: any;

  getParam;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService
  ) { }

  ngOnInit() {

    this.activatedRouter.params.subscribe(query => {

      this.loading
        = true;

      this.success = false;

      this.networkerror = false;

      this.getParam = this.activatedRouter.params['value'].discoName;

      this.loadData();

    });

  }

  loadData() {

    this.httpClient.get<any>(`${environment.APIURL}discos/${this.getParam}`).subscribe(

      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          setTimeout(() => {

            this.calculateProfit();

          }, 1500);


          return true;

        }

        this.networkerror = true;

        return false;


      },

      error => {

        this.loading = false;

        this.networkerror = true;

      }

    );


  }

  calculateProfit() {

    this.profitToday = this.calProfit(this.data.profits.today);

    this.profitWeek = this.calProfit(this.data.profits.week);

    this.profitMonth = this.calProfit(this.data.profits.month);

    this.profitYear = this.calProfit(this.data.profits.year);

  };

  calProfit(data) {

    let amount = 0;

    this.data.profits.gatewayCommission = { 'AEDC': 0.80, 'IKEDC': 0.80, 'PHEDC': 0.80, 'IBEDC': 0.10, 'KNEDC': 0.30, 'KEDCO': 0 };

    for (let i of data) {

      const profit = parseFloat(i.fee) + (parseFloat(i.amount) * (this.data.profits.gatewayCommission[i.distribution_company] / 100));

      amount = amount + profit;
    }

    return amount;

  }

  reloadNetwork() {

    this.ngOnInit();

  }

  calTimeDiff(arg) {
    const time = this.uiservice.updateUserTime(arg);

    return time;
  }

}
