import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../auth/auth.service';
import { UiService } from '../../../../common/ui.service';
import { DataService } from '../../../../common/data.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

interface Iresponse {
  code: number;
  message: string;
}

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss']
})
export class AddStaffComponent implements OnInit {

  loading = false;

  addForm: FormGroup;

  constructor(
    private activatedRouter: ActivatedRoute, private httpClient: HttpClient,
    private authService: AuthService, private uiservice: UiService, private router: Router,
    private cdRef: ChangeDetectorRef, private formbuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<AddStaffComponent>
  ) { }

  ngOnInit() {

    this.addFormData();

  }


  addFormData() {

    this.addForm = this.formbuilder.group({

      name: ['', [Validators.required]],

      email: ['', [Validators.required]],

      password: ['', [Validators.required]],

      role: ['', [Validators.required]]

    });

  }

  createstaff(submmitted: FormGroup) {

    this.loading = true;

    if (submmitted.get('name').invalid) {

      this.loading = false;

      this.uiservice.showToast('Form is required');

    } else if (submmitted.get('email').invalid) {

      this.loading = false;

      this.uiservice.showToast('email is required');

    } else if (submmitted.get('password').invalid) {
      this.loading = false;

      this.uiservice.showToast('password is required');

    } else if (submmitted.get('password').invalid) {
      this.loading = false;

      this.uiservice.showToast('password is required');

    } else if (submmitted.get('role').invalid) {
      this.loading = false;

      this.uiservice.showToast('role is required');

    } else {

      this.httpClient.post<Iresponse>(`${environment.APIURL}staff`, submmitted.value).subscribe(
        data => {

          this.loading = false;


          if (data.code === 1) {

            this.uiservice.showToast(data.message);

            this.dialogRef.close({ status: 'true' });

            return true;
          }

          this.uiservice.showToast(data.message);
          return false;

        },

        error => {

          this.loading = false;

          this.uiservice.showToast('An error occured');

        }
      );

    }

  }




  onClose() {
    this.dialogRef.close();
  }


}
