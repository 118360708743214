import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../auth/auth.service';
import { UiService } from '../../../../common/ui.service';
import { DataService } from '../../../../common/data.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';


interface Iresponse {

  code: number;

  message: string;

}

@Component({
  selector: 'app-fund-wallet',
  templateUrl: './fund-wallet.component.html',
  styleUrls: ['./fund-wallet.component.scss']
})
export class FundWalletComponent implements OnInit {

  updateWallet: FormGroup;

  loading = false;

  constructor(
    private activatedRouter: ActivatedRoute, private httpClient: HttpClient,
    private authService: AuthService, private uiservice: UiService, private router: Router,
    private cdRef: ChangeDetectorRef, private formbuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<FundWalletComponent>
  ) { }

  ngOnInit() {

    this.updateWalletForm();

    this.updateFormData();

  }


  updateWalletForm() {

    this.updateWallet = this.formbuilder.group({

      amount: ['', [Validators.required]],

      uid: ['', [Validators.required]]

    });

  }

  updateFormData() {

    this.updateWallet.patchValue({

      uid: this.dialogData.data.uid

    });

  }


  onClose() {
    this.dialogRef.close();
  }


  walletUpdate(submiited: FormGroup) {

    this.loading = true;

    this.httpClient.put<Iresponse>(`${environment.APIURL}updateuserfund`, submiited.value).subscribe(

      data => {

        this.loading = false;

        if (data.code === 1) {

          this.dialogRef.close({ status: 'true' });

          this.uiservice.showToast(data.message);

          return true;

        }

        this.uiservice.showToast(data.message);

        return false;


      },

      error => {

        this.loading = false;

        this.uiservice.showToast('An error occured');

      }

    );

  }

}
