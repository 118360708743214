import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { UiService } from '../../../common/ui.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';


@Component({
  selector: 'app-listdiscos',
  templateUrl: './listdiscos.component.html',
  styleUrls: ['./listdiscos.component.scss']
})
export class ListdiscosComponent implements OnInit {


  days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  counter;

  loading = false;

  success = false;

  networkerror = false;

  data: any;

  getParam;

  dtOptions: DataTables.Settings = {};

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService
  ) { }

  ngOnInit() {

    this.loading = true;

    this.networkerror = false;

    this.success = false;

    this.getParam = this.activatedRouter.params['value'].discoName;

    this.loadData();

    this.dtOptions = {
      order: []
    };

  }

  loadData() {

    this.httpClient.get<any>(`${environment.APIURL}discos/list/${this.getParam}`).subscribe(
      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data.tnx;

          if (this.data.last_page > 5) {

            this.counter = Array.from({ length: 5 }, (v, k) => k + 1);

          }

          return true;
        }

        this.networkerror = true;

        return false;

      },
      error => {

        this.loading = false;

        this.networkerror = true;
      }
    );

  }


  reloadNetwork() {

    this.ngOnInit();

  }



  loadMoreRecord(page) {

    this.httpClient.get<any>(`${environment.APIURL}discos/list/${this.getParam}?page=${page}`).subscribe(
      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data.tnx;

          //this.counter = Array.from({ length: this.data.last_page }, (v, k) => k + 1);

          if (this.data.last_page > 5) {

            this.counter = Array.from({ length: 5 }, (v, k) => k + 1);

          }


          return true;
        }

        this.networkerror = true;

        return false;

      },
      error => {

        this.loading = false;

        this.networkerror = true;

        this.success = false;
      }
    );

  }


  onPreviouss(lastpage, currentpage) {

    if (currentpage == 1) {
      return;
    }

    if (currentpage <= lastpage) {

      this.loadMoreRecord(currentpage - 1);

    }
  }

  onNext(lastpage, currentpage) {

    if (currentpage < lastpage) {

      this.loadMoreRecord(currentpage + 1);

    }

  }

  calTimeDiff(arg) {
    const time = this.uiservice.updateUserTime(arg);

    return time;
  }

  onSearchResponse(data) {

    var input, filter, table, tr, td, i, txtValue;

    input = document.getElementById("myInput");

    filter = input.value.toUpperCase();

    table = document.getElementById("myTable");

    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {

      td = tr[i].getElementsByTagName("td")[i];

      if (td) {

        txtValue = td.textContent || td.innerText;

        if (txtValue.toUpperCase().indexOf(filter) > -1) {

          tr[i].style.display = "";

        } else {

          tr[i].style.display = "none";

        }

      }
    }
  }

  calculateInterval(event) {

    const nowDate: any = new Date();

    const oldDate: any = new Date(event);

    let r = Math.abs(nowDate.getTime() - oldDate.getTime()) / 1000;

    const days = Math.floor(r / 86400);
    r -= days * 86400;

    const hours = Math.floor(r / 3600) % 24;
    r -= hours * 3600;

    const minutes = Math.floor(r / 60) % 24;
    r -= minutes * 60;

    var diffmin = (nowDate - oldDate) / 60000;

    var diffsec = (nowDate - oldDate) / 1000;

    const seconds = Math.floor(r % 60);

    if (1 > days) {

      if (1 > hours) {

        if (diffsec < 60) {

          return Math.floor(diffsec) + ' Secs';

        } else {

          return Math.floor(diffmin) + ' Min';

        }

      } else {

        return (hours > 1) ? hours + ' Hrs' : hours + ' Hr :' + minutes;

      }

    } else {

      var date = this.months[oldDate.getMonth()] + ' ' + oldDate.getDate() + ', ' + oldDate.getFullYear();

      var hour = oldDate.getHours();

      var amp = hour >= 12 ? 'PM' : 'AM';

      var time = oldDate.getHours() + ":" + oldDate.getMinutes() + ":" + oldDate.getSeconds() + ' ' + amp;

      var dateTime = date + ' ' + time;

      return dateTime;

    }

  }

  checkiftrue(currentpage, initial, above) {

    if (currentpage > initial && currentpage < above) {

      return true;

    }

  }



}
