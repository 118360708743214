import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../auth/auth.service";
import { UiService } from "../../../common/ui.service";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AddFundComponent } from '../staff-management/add-fund/add-fund.component';

@Component({
  selector: 'app-wallet-management',
  templateUrl: './wallet-management.component.html',
  styleUrls: ['./wallet-management.component.scss']
})
export class WalletManagementComponent implements OnInit {

  loading = false;

  networkerror = false;

  success = false;

  getParam;

  data: any;

  submmiting = false;

  walletRequest: FormGroup;

  walletDecline: FormGroup;

  dtOptions: DataTables.Settings = {};

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.walletRequestInit();

    this.walletDeclineInit();

    this.loadData();


    this.dtOptions = {
      order: []
    };
  }

  loadData() {

    this.loading = true;

    this.httpClient.get<any>(`${environment.APIURL}wallet`).subscribe(
      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          return true;
        }

        this.networkerror = true;

        return false;

      },

      error => {

        this.networkerror = true;

        this.loading = false;

      }
    );

  }

  walletRequestInit() {

    this.walletRequest = this.formbuilder.group({

      referenceid: ['', [Validators.required]],

      referenceidupdate: ['', [Validators.required]]

    });

  }

  walletDeclineInit() {

    this.walletDecline = this.formbuilder.group({

      reference: ['', [Validators.required]],

      reason: ['', [Validators.required]]

    });

  }



  reloadNetwork() {
    this.ngOnInit();
  }

  onApprove(data) {

    this.walletRequest.patchValue({
      referenceid: data
    });

    this.onApporveUser(this.walletRequest);
  }


  onApporveUser(submitted: FormGroup) {

    this.submmiting = true;

    if (submitted.get('referenceid').invalid) {

      this.submmiting = false;

      this.uiservice.showToast('reference is required');

    } else {

      this.httpClient.put<any>(`${environment.APIURL}wallet`, submitted.value).subscribe(
        data => {

          this.submmiting = false;


          if (data.code === 1) {

            this.uiservice.showToast(data.message);

            location.reload();

            return true;
          }

          this.uiservice.showToast(data.message);
          return false;

        },

        error => {

          this.loading = false;

          this.uiservice.showToast('An error occured');

        }
      );

    }

  }

  onDecline(data) {

    this.walletDecline.patchValue({

      reference: data

    });


  }

  onDeclineuser(submitted: FormGroup) {
    this.submmiting = true;

    if (submitted.get('reason').invalid) {

      this.submmiting = false;

      this.uiservice.showToast('reason is required');

    } else {

      this.httpClient.put<any>(`${environment.APIURL}wallet/decline`, submitted.value).subscribe(
        data => {

          this.submmiting = false;


          if (data.code === 1) {

            this.uiservice.showToast(data.message);

            location.reload();

            return true;
          }

          this.uiservice.showToast(data.message);
          return false;

        },

        error => {

          this.loading = false;

          this.uiservice.showToast('An error occured');

        }
      );

    }

  }

  requestFund() {

    this.uiservice.openDialogv3(AddFundComponent, this.cdr, {}, (r) => {

      if (r != null && 'status' in r && r['status'] === 'true') {

        this.ngOnInit();

      }


    });

  }



}
