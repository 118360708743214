import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../auth/auth.service';
import { UiService } from '../../../../common/ui.service';
import { DataService } from '../../../../common/data.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

interface Iresponse {
  code: number;
  message: string;
}

@Component({
  selector: 'app-add-fund',
  templateUrl: './add-fund.component.html',
  styleUrls: ['./add-fund.component.scss']
})
export class AddFundComponent implements OnInit {

  loading = false;

  addForm: FormGroup;

  constructor(
    private activatedRouter: ActivatedRoute, private httpClient: HttpClient,
    private authService: AuthService, private uiservice: UiService, private router: Router,
    private cdRef: ChangeDetectorRef, private formbuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<AddFundComponent>
  ) { }

  ngOnInit() {

    this.addFormData();

  }


  addFormData() {

    this.addForm = this.formbuilder.group({

      reference: ['', [Validators.required]],

      userid: ['', [Validators.required]],

      amount: ['', [Validators.required]],

      description: ['', [Validators.required]]

    });

  }

  createFund(submmitted: FormGroup) {

    this.loading = true;

    if (submmitted.get('userid').invalid) {

      this.loading = false;

      this.uiservice.showToast('Form is required');

    } else if (submmitted.get('reference').invalid) {
      this.loading = false;

      this.uiservice.showToast('Reference No is required');

    } else if (submmitted.get('amount').invalid) {

      this.loading = false;

      this.uiservice.showToast('Amount is required');

    } else if (submmitted.get('description').invalid) {
      this.loading = false;

      this.uiservice.showToast('Description is required');

    } else {

      this.httpClient.post<Iresponse>(`${environment.APIURL}walletrequest`, submmitted.value).subscribe(
        data => {

          this.loading = false;


          if (data.code === 1) {

            this.uiservice.showToast(data.message);

            this.dialogRef.close({ status: 'true' });

            return true;
          }

          this.uiservice.showToast(data.message);
          return false;

        },

        error => {

          this.loading = false;

          this.uiservice.showToast('An error occured');

        }
      );

    }

  }




  onClose() {
    this.dialogRef.close();
  }


}
