import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../auth/auth.service";
import { UiService } from "../../../common/ui.service";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import * as localization from 'moment/locale/en-ca';
moment.locale('en-ca', localization);
import readXlsxFile from 'read-excel-file'


@Component({
  selector: 'app-add-promotions',
  templateUrl: './add-promotions.component.html',
  styleUrls: ['./add-promotions.component.scss']
})
export class AddPromotionsComponent implements OnInit {

  submited = false;

  activeDays = new Array();

  loading = false;

  success = false;

  networkerror = false;

  data: any;

  selected: any;

  selectedDate: any;

  alwaysShowCalendars;

  userListData = "";

  locale: any;

  openEndedDecide = true;

  addpromotion: FormGroup;

  isOptIn = false;

  isGlobal = false;

  selectedUser = false;

  userAll = false;

  userselected = false;

  userSelectedItem = false;

  AlluserItem = false;

  valuePromocode = "Promo Code";

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.isOptIn = false;

    this.isGlobal = false;

    this.selectedUser = false;

    this.userAll = false;

    this.userselected = false;

    this.userSelectedItem = false;

    this.AlluserItem = false;

    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.addpromotionForm();

    this.alwaysShowCalendars = true;

    this.activeDays = Array.from({ length: 100 }, (v, k) => k + 1);

    this.loadData();

  }

  loadData() {

    this.httpClient.get<any>(`${environment.APIURL}promotionsAddInfo`).subscribe(

      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          return true;
        }

        this.networkerror = true;

        return false;

      },

      error => {

        this.loading = false;

        this.networkerror = true;
      }

    );

  }

  isInvalidDate(date) {

    return date.weekday() === 0;

  }

  isCustomDate(date) {

    return (
      date.weekday() === 0 ||
      date.weekday() === 6
    ) ? 'mycustomdate' : false;

  }

  addpromotionForm() {

    this.addpromotion = this.formbuilder.group({

      type: ['', [Validators.required]],

      description: ['', [Validators.required]],

      promocode: ['', [Validators.required]],

      promotypes: ['', [Validators.required]],

      promovalue: ['', [Validators.required]],

      activation: ['', [Validators.required]],

      expiry: ['', [Validators.required]],

      activedays: ['', [Validators.required]],

      maxuseperuser: ['', [Validators.required]],

      disco: ['', [Validators.required]],

      benefeciaryuserselecte: ['', [Validators.required]],

      benefeciaryFile: [''],

      filename: ['', [Validators.required]],

      promotionsGlobal: [''],

      promotionsOptIn: [''],

      beneficiaryuserList: [''],

      excludingUser: [''],

      allUser: [''],

      selectedUser: [''],

      opended: ['']

    });

  }

  GeneratePromocode() {

    var promoCode = "";

    var characcter = "ABCDEFGHIJKLMNOPQRSTVWXYZabcdefghijklmnopqrstvwxyz0123456789"

    var codeLength = characcter.length;

    for (var i = 0; i < codeLength; i++) {

      if (promoCode.length < 4) {

        promoCode += characcter.charAt(Math.floor(Math.random() * codeLength));

      }

    }

    this.addpromotion.patchValue({

      promocode: promoCode

    });

    this.valuePromocode = promoCode;

  }

  createpromotion(submmited: FormGroup) {

    this.submited = true;

    this.httpClient.post<any>(`${environment.APIURL}addpromotions`, submmited.value).subscribe(
      data => {

        this.submited = false;

        if (data.code === 1) {

          this.uiservice.showToast(data.message);

          this.router.navigate(['/dashboard/promotions']);

          return true;

        }

        this.uiservice.showToast(data.message);

        return false
      },
      error => {

        this.submited = false;

        this.uiservice.showToast("An error occured. Ensure internet connection is working and promotion data are provided");

      }
    );

  }

  updateChoice(event) {

    this.isOptIn = false;

    this.isGlobal = false;

    switch (event.target.value) {

      case "global":

        this.isGlobal = true;

        this.isOptIn = false;

        this.addpromotion.patchValue({

          promotionsGlobal: event.target.value

        });

        break;

      case "opt-in":

        this.isOptIn = true;

        this.isGlobal = false;

        this.addpromotion.patchValue({

          promotionsOptIn: event.target.value,

          allUser: true,

          selectedUser: false

        });

        this.isOptIn = true;

        this.userAll = true;

        this.selectedUser = false;

        this.AlluserItem = true;


        break;

    }

  }

  updatedUser(event) {

    this.userAll = false;

    this.selectedUser = false;

    this.userSelectedItem = false;

    this.AlluserItem = false;

    switch (event.target.value) {

      case "all-users":

        this.addpromotion.patchValue({

          allUser: true,

          selectedUser: false

        });

        this.userAll = true;

        this.selectedUser = false;

        this.AlluserItem = true;

        this.userSelectedItem = false;

        break;

      case "selected-users":

        this.selectedUser = true;

        this.userAll = false;

        this.AlluserItem = false;

        this.userSelectedItem = true;

        this.addpromotion.patchValue({

          allUser: false,

          selectedUser: true

        });

        break;
    }

  }

  OpenEnded(event) {

    this.openEndedDecide = !this.openEndedDecide;

    this.cd.detectChanges();
  }

  updateexcluded(event) {

    this.addpromotion.patchValue({

      excludingUser: event.target.value

    });

  }

  updatedselecteduser(event) {

    this.addpromotion.patchValue({

      beneficiaryuserList: (this.userListData.length > 0) ? ',' + event.target.value : event.target.value

    });

  }

  checkFileType(event) {

    const filetypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

    if (filetypes.includes(event.target.files[0].type)) {

      if (filetypes[1] === event.target.files[0].type) {

        readXlsxFile(event.target.files[0]).then((data) => {

          var userList = '';

          for (let i of data) {

            userList += (userList.length > 0) ? ',' + i[0] : i[0];

          }

          this.userListData = (this.userListData.length > 0) ? ',' + userList : userList;

          this.addpromotion.patchValue({

            beneficiaryuserList: this.userListData

          });

        });

      }



    } else {

      this.uiservice.showToast("File type must be a valid excel or csv file");

    }

  }

  expirydate(event) {

    this.addpromotion.patchValue({

      expiry: event.startDate._d.toString().split('(')[0]

    });

  }

  startdata(event) {

    this.addpromotion.patchValue({

      activation: event.startDate._d.toString().split('(')[0]

    });

  }

}
