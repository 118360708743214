import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from '../auth/auth.service';
import {UiService} from '../common/ui.service';
import {FormBuilder, FormGroup, Validators, NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AdminService} from '../services/admin.service';

interface Iresponse {

  code: number;

  message: string;

  token: string;

}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading = false;

  loginForm: FormGroup;

  getParam;

  constructor(
    private router: Router, private activatedRouter: ActivatedRoute, private httpClient: HttpClient,
    private authService: AuthService, private uiservice: UiService, private formbuilder: FormBuilder, private authservice: AuthService
  ) {
  }

  ngOnInit() {

    this.getParam = this.activatedRouter.snapshot.queryParamMap.get('redirectUrl');

    this.loginFormInit();

  }

  loginFormInit() {

    this.loginForm = this.formbuilder.group({

      username: ['', [Validators.required, Validators.email]],

      password: ['', [Validators.required]]

    });

  }


  onSubmit(submitted: FormGroup) {

    this.loading = true;

    if (submitted.get('username').invalid) {

      this.loading = false;

      this.uiservice.showToast('Email is required and must be a valid email address');

    } else if (submitted.get('password').invalid) {

      this.loading = false;

      this.uiservice.showToast('Phone number is required');

    } else {


      this.httpClient.post<Iresponse>(`${environment.APIURL}login`, submitted.value).subscribe(
        data => {

          this.loading = false;

          if (data.code === 1) {

            this.authservice.login(data.token);

            if (this.authservice.isAuthenticate()) {

              if (['customer', 'reseller'].includes(data['role'])) {
                this.router.navigate([`/dashboard/account/${data['id']}`]);
                return;
              }

              if (this.getParam) {

                this.router.navigate([this.getParam]);

                return true;
              }

              this.router.navigate(['/dashboard']);

            }

            return true;

          }

          this.uiservice.showToast(data.message);

          return false;

        },

        error => {

          console.log(error);

          this.loading = false;

          this.uiservice.showToast('User Triggered an error');

        }
      );

    }

  }


}
