import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../auth/auth.service";
import { UiService } from "../../../common/ui.service";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AddStaffComponent } from './add-staff/add-staff.component';

import { EditStaffComponent } from './edit-staff/edit-staff.component';

@Component({
  selector: 'app-staff-management',
  templateUrl: './staff-management.component.html',
  styleUrls: ['./staff-management.component.scss']
})
export class StaffManagementComponent implements OnInit {

  loading = false;

  networkerror = false;

  success = false;

  getParam;

  data: any;

  dtOptions: DataTables.Settings = {};

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.loadData();


    this.dtOptions = {
      order: []
    };
  }


  loadData() {

    this.loading = true;

    this.httpClient.get<any>(`${environment.APIURL}staff`).subscribe(
      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          return true;
        }

        this.networkerror = true;

        return false;

      },

      error => {

        this.networkerror = true;

        this.loading = false;

      }
    );

  }

  addStaff() {

    this.uiservice.openDialogv3(AddStaffComponent, this.cdr, {}, (r) => {

      if (r != null && 'status' in r && r['status'] === 'true') {

        this.ngOnInit();

      }


    });

  }

  editStaff(dtas) {

    this.uiservice.openDialogv3(EditStaffComponent, this.cdr, { data: dtas }, (r) => {

      if (r != null && 'status' in r && r['status'] === 'true') {

        this.ngOnInit();

      }


    });

  }


  delateuser(datas) {

    this.uiservice.showToast('Staff data is being deleted');

    this.httpClient.delete<any>(`${environment.APIURL}staff/${datas}`).subscribe(
      data => {

        if (data.code === 1) {

          this.uiservice.showToast(data.message);

          this.ngOnInit();

          return true;
        }

        this.uiservice.showToast(data.message);

        return false;
      },
      error => {

        this.uiservice.showToast('An error occured');

      }
    );
  }


  reloadNetwork() {
    this.ngOnInit();
  }


  checkPrivilage() {

    if (this.data.Staff == 'Supervisor' || this.data.Staff == 'Manager') {

      return true;

    }

    return false;

  }

}
