import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../auth/auth.service';
import { UiService } from '../../../../common/ui.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  submitted = false;

  loading = false;

  networkerror = false;

  success = false;

  data: any;

  getParam: any;

  EmailForm: FormGroup;

  transactionStatus: FormGroup;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService
  ) {

    this.getParam = activatedRouter.params['value'].id;

    this.transactionstausInit();

    this.transactionStatus.patchValue({

      tnxid: this.getParam

    });

  }

  ngOnInit() {

    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.loadData();

    this.EmailFormInit();

  }


  loadData() {


    this.httpClient.get<any>(`${environment.APIURL}transactions/preview/${this.getParam}`).subscribe(

      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          return true;
        }


        this.networkerror = true;

        return false;

      },
      error => {

        this.networkerror = true;

        this.loading = false;

      }
    );

  }

  transactionstausInit() {

    this.transactionStatus = this.formbuilder.group({

      'status': ['', [Validators.required]],

      'tnxid': ['', [Validators.required]]

    });

  }

  OnUpdateStatus(submitteddata: FormGroup) {

    this.submitted = true;

    if (submitteddata.valid) {

      this.httpClient.put<any>(`${environment.APIURL}transaction/updatestatus`, this.transactionStatus.value).subscribe(

        data => {

          this.submitted = false;

          if (data.code === 1) {

            this.uiservice.showToast(data.message);

            this.ngOnInit();

            return true;
          }

          this.uiservice.showToast(data.message);

          return false;
        },
        error => {

          this.submitted = false;

          this.uiservice.showToast('An error occured');

        }

      );

    } else {

      this.submitted = false;

      this.uiservice.showToast('Kindly selected current status');

    }

  }

  EmailFormInit() {

    this.EmailForm = this.formbuilder.group({

      email: ['', [Validators.required]],

      myPdf: ['', [Validators.required]]

    });

  }

  reloadNetwork() {

    this.ngOnInit();

  }


  splitoken(event) {

    if (!event) {
      return;
    }

    const seperatedByDash = event.split('-');

    const data = seperatedByDash.join('');

    if (data) {

      const token = data.split('');

      let usertoken = '';

      let tokenupdate = token;

      for (let i = 0; i < tokenupdate.length; i++) {

        // tslint:disable-next-line: radix
        if (tokenupdate.length === 3) {

          usertoken += tokenupdate.join('');

        } else if (tokenupdate.length > 3) {

          const newtoken = tokenupdate.splice(4);

          usertoken += tokenupdate.join('') + '-';

          tokenupdate = newtoken;

          if (tokenupdate.length === 4) {

            usertoken += tokenupdate.join('');

          }

        } else {

          usertoken += tokenupdate.join('');

        }
      }

      return usertoken;
    }

    return 'Transaction Pending';

  }

  exportAsPDF() {

    let data = document.getElementById("MyDIv");

    html2canvas(data).then(canvas => {

      const contentDrawer = canvas.toDataURL("image/png");

      let pdf = new jspdf('1', 'cm', 'a4');

      pdf.addImage(contentDrawer, 'PNG', 0, 0, 29.7, 21.0);

      pdf.save(this.data.details.reference + '.pdf');

    });

  }

  OnKeyValue(event) {

    this.EmailForm.patchValue({

      email: event.target.value

    });

  }

  SendMail() {

    let data = document.getElementById("MyDIv");

    html2canvas(data).then(canvas => {

      const contentDrawer = canvas.toDataURL("image/png");

      let pdf = new jspdf('1', 'cm', 'a4');

      pdf.addImage(contentDrawer, 'PNG', 0, 0, 29.7, 21.0);

      var file = pdf.output('datauristring');        //returns the data uri string

      this.EmailForm.patchValue({

        myPdf: file

      });

    });

    if (this.EmailForm.valid) {

      this.httpClient.post<any>(`${environment.APIURL}sendtransactionmail`, this.EmailForm.value).subscribe(
        data => {

          if (data.code == 1) {


          } else {

            this.uiservice.showToast(data.message);

          }

        },
        error => {

          this.uiservice.showToast("An error occured sending attachement");

        }
      );

    } else {

      this.uiservice.showToast("Email is required to forward attached transaction details");

    }
  }

  calculateCost(event) {

    return event * (1 - (7.5 / 100));

  }


  calculateVat(event) {

    if (!event) {

      return 0;

    }

    return event * (7.5 / 100);

  }

  calculatetotalCharge(a, b) {

    return parseInt(a) + parseInt(b);

  }

}
