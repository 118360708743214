import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../auth/auth-guard.services';
import { UserComponent } from './user/user.component';
import { TransactionComponent } from './transaction/transaction.component';
import { ListComponent } from './transaction/list/list.component';
import { DetailsComponent } from './transaction/list/details/details.component';
import { AccountComponent } from './account/account.component';
import { UserDetailsComponent } from './account/user-details/user-details.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { AddPromotionsComponent } from './promotions/add-promotions/add-promotions.component';
import { StaffManagementComponent } from './admin/staff-management/staff-management.component';
import { WalletManagementComponent } from './admin/wallet-management/wallet-management.component';
import { DiscosComponent } from './discos/discos.component';
import { ListdiscosComponent } from './discos/listdiscos/listdiscos.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard/home',
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      { path: 'home', component: UserComponent, canActivate: [AuthGuard] },
      {
        path: 'transaction',
        component: TransactionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'transaction/list',
        component: ListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'transaction/details/:id',
        component: DetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account/:id',
        component: UserDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'promotions',
        component: PromotionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'promotions/addnew',
        component: AddPromotionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'staff-mangement',
        component: StaffManagementComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'wallet-request',
        component: WalletManagementComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'discos/:discoName',
        component: DiscosComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'discos/list/:discoName',
        component: ListdiscosComponent,
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
