import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../auth/auth.service';
import { UiService } from '../../../../common/ui.service';
import { DataService } from '../../../../common/data.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

interface Iresponse {
  code: number;
  message: string;
}

@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.scss']
})
export class EditStaffComponent implements OnInit {

  loading = false;

  editForm: FormGroup;

  toggele = true;

  passwordForm: FormGroup;

  constructor(
    private activatedRouter: ActivatedRoute, private httpClient: HttpClient,
    private authService: AuthService, private uiservice: UiService, private router: Router,
    private cdRef: ChangeDetectorRef, private formbuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<EditStaffComponent>
  ) {

  }

  ngOnInit() {

    this.editformInit();

    this.passwordFormInit();

    this.updateFormData();


  }


  passwordFormInit() {

    this.passwordForm = this.formbuilder.group({

      password: ['', [Validators.required]],

      uid: ['', [Validators.required]],

    });

  }


  editformInit() {

    this.editForm = this.formbuilder.group({

      name: ['', [Validators.required]],

      email: ['', [Validators.required]],

      uid: ['', [Validators.required]],

      role: ['', [Validators.required]]

    });

  }

  updateFormData() {

    this.editForm.patchValue({

      uid: this.dialogData.data.uid,

      email: this.dialogData.data.email,

      name: this.dialogData.data.name,

      role: this.dialogData.data.role

    });

    this.passwordForm.patchValue({
      uid: this.dialogData.data.uid
    });

  }


  edit(submmitted: FormGroup) {

    this.loading = true;

    if (submmitted.get('name').invalid) {

      this.loading = false;

      this.uiservice.showToast('Form is required');

    } else if (submmitted.get('email').invalid) {

      this.loading = false;

      this.uiservice.showToast('email is required');

    } else if (submmitted.get('role').invalid) {
      this.loading = false;

      this.uiservice.showToast('role is required');

    } else {

      this.httpClient.put<Iresponse>(`${environment.APIURL}staff`, submmitted.value).subscribe(
        data => {

          this.loading = false;


          if (data.code === 1) {

            this.uiservice.showToast(data.message);

            this.dialogRef.close({ status: 'true' });

            return true;
          }

          this.uiservice.showToast(data.message);
          return false;

        },

        error => {

          this.loading = false;

          this.uiservice.showToast('An error occured');

        }
      );

    }

  }


  editPassword(submmitted: FormGroup) {

    this.loading = true;

    if (submmitted.get('password').invalid) {

      this.loading = false;

      this.uiservice.showToast('Password is required');

    } else {

      this.httpClient.put<Iresponse>(`${environment.APIURL}staff/updatepassword`, submmitted.value).subscribe(
        data => {

          this.loading = false;


          if (data.code === 1) {

            this.uiservice.showToast(data.message);

            this.dialogRef.close({ status: 'true' });

            return true;
          }

          this.uiservice.showToast(data.message);
          return false;

        },

        error => {

          this.loading = false;

          this.uiservice.showToast('An error occured');

        }
      );

    }

  }

  onClose() {
    this.dialogRef.close();
  }

  onPasswordUpdate() {

    this.toggele = !this.toggele;

  }
}
