import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { UiService } from '../../common/ui.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';


@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {
  loading = false;

  success = false;

  networkerror = false;

  data: any;

  selected: any;

  alwaysShowCalendars: boolean;

  ranges: any = {

    Today: [moment(), moment()],

    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],

    'Last 7 Days': [moment().subtract(6, 'days'), moment()],

    'Last 30 Days': [moment().subtract(29, 'days'), moment()],

    'This Month': [moment().startOf('month'), moment().endOf('month')],

    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

  };

  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {

    return this.invalidDates.some(d => d.isSame(m, 'day'));

  }

  chartReady = false;

  lineChartData: ChartDataSets[] = [{
    data: [79, 90, 20, 30, 20], 'label': 'Transaction'
  }];

  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];


  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'transparent',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.loadData();
  }

  loadData() {

    this.httpClient.get<any>(`${environment.APIURL}transaction`).subscribe(
      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          return true;
        }

        this.networkerror = true;

        return false;

      },
      error => {

        this.loading = false;

        this.networkerror = true;

      }
    );

  }

  onUpdate(event) {
    if (event.endDate !== null) {

      const startdate = event.startDate._d.toString().split('(')[0];

      const enddate = event.endDate._d.toString().split('(')[0];

      this.loading = true;

      this.success = false;

      this.loadnewData(startdate, enddate);

    }
  }


  loadnewData(start, end) {

    this.httpClient.get<any>(`${environment.APIURL}transaction/${start}/${end}`).subscribe(
      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          return true;
        }

        return false;

      },
      error => {
        this.uiservice.showToast('An error occured loading new data');

        this.loading = false;

        this.success = true;

      }
    );

  }

  reloadNetwork() {

    this.ngOnInit();

  }

  checkifNegative(data) {

    if (data < 0) {
      return false;
    }
    return true;

  }


}
