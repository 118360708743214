import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../auth/auth.service';
import { UiService } from '../../../../common/ui.service';
import { DataService } from 'src/app/common/data.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';


interface Iresponse {

  code: number;

  message: string;

}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  updateUser: FormGroup;

  loading = false;

  constructor(
    private activatedRouter: ActivatedRoute, private httpClient: HttpClient,
    private authService: AuthService, private uiservice: UiService, private router: Router,
    private cdRef: ChangeDetectorRef, private formbuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<EditUserComponent>
  ) { }

  ngOnInit() {

    this.updateUserForm();

    this.updateValue();
  }


  updateUserForm() {

    this.updateUser = this.formbuilder.group({

      firstname: ['', [Validators.required]],

      email: ['', [Validators.required]],

      phone: ['', [Validators.required]],

      dob: ['', [Validators.required]],

      uid: ['', [Validators.required]]

    });

  }

  updateValue() {

    const day = (this.dialogData.data.dob_day) ? this.dialogData.data.dob_day : 'DD';

    const month = (this.dialogData.data.dob_month) ? this.dialogData.data.dob_month : 'MM'

    this.updateUser.patchValue({

      firstname: this.dialogData.data.name,

      email: this.dialogData.data.email,

      phone: this.dialogData.data.phone,

      dob: month + '-' + day,

      uid: this.dialogData.data.uid

    });
  }


  editUser(submitted: FormGroup) {

    this.loading = true;

    this.httpClient.put<Iresponse>(`${environment.APIURL}edituser`, submitted.value).subscribe(

      data => {

        this.loading = false;

        if (data.code === 1) {

          this.dialogRef.close({ status: 'true' });

          this.uiservice.showToast(data.message);

          return true;

        }

        this.uiservice.showToast(data.message);

        return false;


      },

      error => {

        this.loading = false;

        this.uiservice.showToast('An error occured');

      }

    );

  }

  onClose() {
    this.dialogRef.close();
  }

}
