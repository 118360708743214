import { Component, OnInit, Output, OnChanges, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss']
})
export class NetworkErrorComponent implements OnInit, OnChanges {
  @Output() reloadNetwork = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  reloadnetwork() {
    // trigger reload in component
    this.reloadNetwork.emit();
  }

}
