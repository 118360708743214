import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../auth/auth.service";
import { UiService } from "../../../common/ui.service";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { FundWalletComponent } from "./fund-wallet/fund-wallet.component";
import {AdminService} from '../../../services/admin.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, AfterContentChecked {

  dtOptions: DataTables.Settings = {};

  loading = false;

  networkerror = false;

  success = false;

  getParam;

  data: any;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService,
    public adminService : AdminService,
    private cdr: ChangeDetectorRef
  ) {

    this.getParam = activatedRouter.params['value'].id;

  }

  ngOnInit() {

    this.loading = true;

    this.success = false;

    this.networkerror = false;

    this.loadData();


    this.dtOptions = {
      order: []
    };
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  role;

  loadData() {

    this.httpClient.get<any>(`${environment.APIURL}users/${this.getParam}`).subscribe(

      data => {

        this.loading = false;

        if (data.code === 1) {

          this.success = true;

          this.data = data;

          this.role = data.role;

          return true;
        }

        this.networkerror = true;

        return false;

      },

      error => {

        this.networkerror = true;

        this.loading = false;

      }

    );

  }


  checkLastTransaction(data: []) {

    const getLastData = data.pop();

    let s = 0;

    if (getLastData) {

      const lastTnx = this.uiservice.updateUserTime(getLastData['created_at']);

      s = lastTnx;

    }

    return s;

  }

  countData(data: []) {

    return data.length;

  }

  reloadNetwork() {
    this.ngOnInit();
  }


  calTimeDiff(arg) {

    const time = this.uiservice.updateUserTime(arg);

    return time;

  }

  checkIf(data: []) {

    if (data.length > 0) {

      return false;

    }

    return true;
  }


  editUser() {
    this.uiservice.openDialogv3(EditUserComponent, this.cdr, { data: this.data.user }, (r) => {

      if (r != null && 'status' in r && r['status'] === 'true') {

        this.ngOnInit();

      }

    });
  }

  fundwallet() {
    this.uiservice.openDialogv3(FundWalletComponent, this.cdr, { data: this.data.user }, (r) => {

      if (r != null && 'status' in r && r['status'] === 'true') {

        this.ngOnInit();

      }

    });


  }

  makeApiReseller(){

    let data = {
        uid : this.data.user.uid
     };
    this.loading = true;

    this.adminService.toggleApiReseller(data).subscribe((success:any)=>{
      this.loading = false;
      this.uiservice.showToast(success.message);
      this.loadData();


    },(error)=>{
      this.loading = false;
      this.uiservice.showToast(error.error.message);
    });


  }

}
