import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth/auth.service";
import { UiService } from "../../common/ui.service";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"]
})
export class UserComponent implements OnInit {

  days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]


  loading = false;

  success = false;

  networkerror = false;

  chartsuccess = false;

  profitToday = 0;

  profitWeek = 0;

  profitMonth = 0;

  profitYear = 0;

  data: any;

  toggleVal = "All";

  chartReady = false;

  lineChartData: ChartDataSets[] = [];

  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];


  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'transparent',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  toggleValChart = 'Week';

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    private uiservice: UiService,
    private formbuilder: FormBuilder,
    private authservice: AuthService,
    public adminService : AdminService,
  ) { }

  ngOnInit() {
    this.loading = true;

    this.profitToday = 0;

    this.profitWeek = 0;

    this.profitMonth = 0;

    this.profitYear = 0;

    this.success = false;

    this.networkerror = false;

    this.chartsuccess = false;

    this.loadData();


    this.chartReady = true;

  }

  loadData() {
    this.httpClient.get<any>(`${environment.APIURL}dashboard`).subscribe(
      data => {
        this.loading = false;

        if (data.code === 1) {
          this.success = true;

          this.data = data;

          setTimeout(() => {

            this.calculateProfitAll(data);

          }, 2500);


          return true;
        }

        this.networkerror = false;

        return false;
      },

      error => {
        this.loading = false;

        this.networkerror = true;
      }
    );
  }

  calculateProfitAll(data) {

    this.profitToday = this.calProfit(data.profits.today, data.profits.wallet, data.profits.api, data.profits.gateway);

    this.profitWeek = this.calProfit(data.profits.week, data.profits.wallet, data.profits.api, data.profits.gateway);

    this.profitMonth = this.calProfit(data.profits.month, data.profits.wallet, data.profits.api, data.profits.gateway);

    this.profitYear = this.calProfit(data.profits.year, data.profits.wallet, data.profits.api, data.profits.gateway);

    this.calProfitChart(data.profits.week, data.profits.wallet, data.profits.api, data.profits.gateway)
  }

  calProfit(data: any, wallet, api, gateway) {

    let amount = 0;

    this.data.profits.gatewayCommission = { 'AEDC': 0.80, 'IKEDC': 0.80, 'PHEDC': 0.80, 'IBEDC': 0.10, 'KNEDC': 0.30, 'KEDCO': 0 };

    for (let i of data) {

      const profit = parseFloat(i.fee) + (parseFloat(i.amount) * (this.data.profits.gatewayCommission[i.distribution_company] / 100));

      amount = amount + profit;
    }

    return amount;
  }

  calProfitChart(data: any, wallet, api, gateway) {

    this.chartsuccess = false;

    const weekholder = { 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [], 'Sunday': [] };

    const chartX = [];

    this.lineChartLabels = this.weeks();

    this.data.profits.gatewayCommission = { 'AEDC': 0.80, 'IKEDC': 0.80, 'PHEDC': 0.80, 'IBEDC': 0.10, 'KNEDC': 0.30, 'KEDCO': 0 };

    for (let i of data) {

      const date = new Date(i.created_at);

      const profit = parseFloat(i.fee) + (parseFloat(i.amount) * (this.data.profits.gatewayCommission[i.distribution_company] / 100));

      chartX.push(this.weeks()[date.getDay()]);

      weekholder[this.weeks()[date.getDay()]].push(profit);

    }

    var sunday = weekholder['Sunday'].reduce((a, b) => a + b, 0);

    var monday = weekholder['Monday'].reduce((a, b) => a + b, 0);

    var tuesday = weekholder['Tuesday'].reduce((a, b) => a + b, 0);

    var wednesday = weekholder['Wednesday'].reduce((a, b) => a + b, 0);

    var thursday = weekholder['Thursday'].reduce((a, b) => a + b, 0);

    var friday = weekholder['Friday'].reduce((a, b) => a + b, 0);

    var saturday = weekholder['Saturday'].reduce((a, b) => a + b, 0);

    this.lineChartData = [];

    this.lineChartData.push({
      'data':
        [
          sunday,

          monday,

          tuesday,

          wednesday,

          thursday,

          friday,

          saturday

        ], 'label': 'Profit'
    });

    this.chartsuccess = true;
  }

  reloadNetwork() {
    this.ngOnInit();
  }

  onToggle(event) {

    this.toggleVal = event;

  }

  onToggleChart(event) {

    this.toggleValChart = event;

    if (event == 'Week') {

      this.calProfitChart(this.data.profits.week, this.data.profits.wallet, this.data.profits.api, this.data.profits.gateway)

    }

    if (event == 'Month') {

      this.calProfitChartMonthly();

    }

    if (event == 'Year') {

      this.calProfitChartYearly();

    }

  }

  calTimeDiff(arg) {
    const time = this.uiservice.updateUserTime(arg);

    return time;
  }

  calculate(data) {

    const result = data.length / 2;

    if ((data.length % 2) == 0) {


      return result;

    } else {

      const resultArray = result.toString().split('.');

      return resultArray[0];
    }


  }

  weeks() {

    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  }

  calProfitChartMonthly() {

    this.chartsuccess = false;;

    var weeksHolder = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];

    var weeks = {

      'Week 1': { 'Sunday': [], 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [] },

      'Week 2': { 'Sunday': [], 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [] },

      'Week 3': { 'Sunday': [], 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [] },

      'Week 4': { 'Sunday': [], 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [] },

      'Week 5': { 'Sunday': [], 'Monday': [], 'Tuesday': [], 'Wednesday': [], 'Thursday': [], 'Friday': [], 'Saturday': [] }

    };

    const data = this.data.profits.month;

    const wallet = this.data.profits.wallet;

    const api = this.data.profits.api;

    const gateway = this.data.profits.gateway;

    this.data.profits.gatewayCommission = { 'AEDC': 0.80, 'IKEDC': 0.80, 'PHEDC': 0.80, 'IBEDC': 0.10, 'KNEDC': 0.30, 'KEDCO': 0 };

    for (let i of data) {

      const date = new Date(i.created_at);

      const profit = parseFloat(i.fee) + (parseFloat(i.amount) * (this.data.profits.gatewayCommission[i.distribution_company] / 100));

      weeks[weeksHolder[Math.floor(date.getDate() / 7)]][this.weeks()[date.getDay()]].push(profit);

    }

    this.lineChartData = [];

    for (let s of weeksHolder) {

      var sunday = weeks[s]['Sunday'].reduce((a, b) => a + b, 0);

      var monday = weeks[s]['Monday'].reduce((a, b) => a + b, 0);

      var tuesday = weeks[s]['Tuesday'].reduce((a, b) => a + b, 0);

      var wednesday = weeks[s]['Wednesday'].reduce((a, b) => a + b, 0);

      var thursday = weeks[s]['Thursday'].reduce((a, b) => a + b, 0);

      var friday = weeks[s]['Friday'].reduce((a, b) => a + b, 0);

      var saturday = weeks[s]['Saturday'].reduce((a, b) => a + b, 0);

      this.lineChartData.push({ 'data': [sunday, monday, tuesday, wednesday, thursday, friday, saturday], 'label': s });

    }

    this.lineChartLabels = this.weeks();


    this.chartsuccess = true;
  }


  calProfitChartYearly() {

    this.chartsuccess = false;

    const data = this.data.profits.year;

    const wallet = this.data.profits.wallet;

    const api = this.data.profits.api;

    const gateway = this.data.profits.gateway;

    const yearholder = {
      'January': [],
      'February': [],
      'March': [],
      'April': [],
      'May': [],
      'June': [],
      'July': [],
      'August': [],
      'September': [],
      'October': [],
      'November': [],
      'December': []
    };

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    this.data.profits.gatewayCommission = { 'AEDC': 0.80, 'IKEDC': 0.80, 'PHEDC': 0.80, 'IBEDC': 0.10, 'KNEDC': 0.30, 'KEDCO': 0 };

    for (let i of data) {

      const date = new Date(i.created_at);

      const profit = parseFloat(i.fee) + (parseFloat(i.amount) * (this.data.profits.gatewayCommission[i.distribution_company] / 100));

      yearholder[monthNames[date.getMonth()]].push(profit);

    }

    this.lineChartLabels = monthNames;

    var January = yearholder['January'].reduce((a, b) => a + b, 0);

    var February = yearholder['February'].reduce((a, b) => a + b, 0);

    var March = yearholder['March'].reduce((a, b) => a + b, 0);

    var April = yearholder['April'].reduce((a, b) => a + b, 0);

    var May = yearholder['May'].reduce((a, b) => a + b, 0);

    var June = yearholder['June'].reduce((a, b) => a + b, 0);

    var July = yearholder['July'].reduce((a, b) => a + b, 0);

    var August = yearholder['August'].reduce((a, b) => a + b, 0);

    var September = yearholder['September'].reduce((a, b) => a + b, 0);

    var October = yearholder['October'].reduce((a, b) => a + b, 0);

    var November = yearholder['November'].reduce((a, b) => a + b, 0);

    var December = yearholder['December'].reduce((a, b) => a + b, 0);

    this.lineChartData = [];

    this.lineChartData.push({
      'data':
        [
          January,

          February,

          March,

          April,

          May,

          June,

          July,

          August,

          September,

          October,

          November,

          December
        ], 'label': 'Month'
    });


    this.chartsuccess = true;
  }


  calculateInterval(event) {

    const nowDate: any = new Date();

    const oldDate: any = new Date(event);

    let r = Math.abs(nowDate.getTime() - oldDate.getTime()) / 1000;

    const days = Math.floor(r / 86400);
    r -= days * 86400;

    const hours = Math.floor(r / 3600) % 24;
    r -= hours * 3600;

    const minutes = Math.floor(r / 60) % 24;
    r -= minutes * 60;

    var diffmin = (nowDate - oldDate) / 60000;

    var diffsec = (nowDate - oldDate) / 1000;

    const seconds = Math.floor(r % 60);

    if (1 > days) {

      if (1 > hours) {

        if (diffsec < 60) {

          return Math.floor(diffsec) + ' Secs';

        } else {

          return Math.floor(diffmin) + ' Min';

        }

      } else {

        return (hours > 1) ? hours + ' Hrs' : hours + ' Hr';

      }

    }
    else {

      var date = this.months[oldDate.getMonth()] + ' ' + oldDate.getDate() + ', ' + oldDate.getFullYear();

      var hour = oldDate.getHours();

      var amp = hour >= 12 ? 'PM' : 'AM';

      var time = oldDate.getHours() + ":" + oldDate.getMinutes() + ":" + oldDate.getSeconds() + ' ' + amp;

      var dateTime = date + ' ' + time;

      return dateTime;

    }

  }

  lastSeen(time) {

    if (!time) {
      return '-';
    }

    return this.uiservice.updateUserTime(time);
  }

  count(data) {

    if (data) {

      return data.length;
    }

    return 0;

  }

  retryTransactions(){

    let data = {

    };
    this.loading = true;

    this.adminService.retryTransactions(data).subscribe((success:any)=>{
      this.loading = false;
      console.log(success);
      this.uiservice.showToast(success.message);


    },(error)=>{
      this.loading = false;
      this.uiservice.showToast(error.error.message);
    });


  }


}
